
@media all and (max-width: $breakpoints_xxl) {
    .page-taxonomy-term .vocabulary-product-category .group-samenstelling .field-name-field-samenstellingomschrijving {
        font-size: 19px;
    }
    @include leeftijdsfase_header($breakpoints_xxl);
    
    .page-taxonomy-term #block-views-productlijn-overzicht-block {
        max-width: 1280px;
    }
    .node-type-home .view-home-leeftijdenwidget.slide .view-header,
    .page-search .region-block .block-center-max {
        padding: 0 15px;
        box-sizing: border-box;
    }
    .node-type-home .view-home-leeftijdenwidget.slide .view-header .slide-close {
        right: 15px;
    }
    .node-type-leeftijdsfase .view-leeftijdsfase-tips {
        padding: 0 15px;
        
        .views-field-field-tips-titel h3 {
            font-size: 32px;
            line-height: 40px;
        }
        .views-field-field-tips-1 li {
            
        }
    }
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina {
        .views-field-field-headertekst,
        .backlink {
            padding: 0 15px;
            box-sizing: border-box;
        }
    }
}

@media (max-width: 1320px) {
    #header nav .main-menu .menu li .menu {
        top: 65px;
    }
}

@media all and (max-width: $breakpoints_xl) {
    @include leeftijdsfase_header($breakpoints_xl);
    
}
@media all and (max-width: $breakpoints_l) {
    @include leeftijdsfase_header($breakpoints_l);
    
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-headertekst h1 {
        font-size: 48px;
    }
    #header nav .main-menu #block-system-main-menu {
        left: 40%;
    }
}
@media all and (max-width: $breakpoints_m) {
    @include leeftijdsfase_header($breakpoints_m);
    
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-headertekst h1 {
        font-size: 44px;
    }
}
@media all and (max-width: $breakpoints_tablet) {
    @include leeftijdsfase_header($breakpoints_tablet);
    
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-headertekst h1 {
        font-size: 34px;
    }
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina, 
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-startafbeelding, 
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .home-bg-image {
        height: 500px;
    }
    .node-type-leeftijdsfase .group-subbox .field.field-name-field-usp-afbeelding img {
        width: 80px;
        margin: 30px 0 10px;
    }
    .node-type-leeftijdsfase .group-subbox .field.field-name-field-subalinea p {
        font-size: 16px;
    }
    .page-taxonomy-term .vocabulary-product-category .group-samenstelling .field-name-field-samenstellingomschrijving {
        font-size: 15px;
    }
}
@media all and (max-width: ($breakpoints_tablet - 1)) {
    .slicknav_menu {
        z-index: 10001;
        position: absolute;
        width: 100%;
    }
    
    .toggle {
        display: block;
        position: absolute;
        top: 10px;
        right: 0px;
        margin: 0;
        padding: 0;
        width: 46px;
        height: 46px;
        text-indent: -9999px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: 0;
        border: none;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        z-index: 998;
        background: transparent;
    }
    .toggle span {
        display: block;
        position: absolute;
        top: 23px;
        left: 11px;
        width: 25px;
        height: 2px;
        background: $colors_red;
        -webkit-transition: background 0s 0.3s;
        transition: background 0s 0.3s;
    }
    .toggle span::before, .toggle span::after {
        -webkit-transition-duration: 0.3s,0.3s;
        transition-duration: 0.3s,0.3s;
        -webkit-transition-delay: 0.3s,0s;
        transition-delay: 0.3s,0s;
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $colors_red;
        content: "";
    }
    .toggle span::before {
        -webkit-transition-property: top,-webkit-transform;
        transition-property: top,-webkit-transform;
        transition-property: top,transform;
        -webkit-transition-property: top,-webkit-transform,-webkit-transform;
        transition-property: top,transform,-webkit-transform;
        top: -6px;
    }
    
    .menu-wrapper.active .toggle {
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
    }
    .toggle.is-active span {
        background-color: #fff;
    }
    .toggle.is-active span::before, 
    .toggle.is-active span::after {
        -webkit-transition-delay: 0s,0.3s;
        transition-delay: 0s,0.3s;
        background-color: $colors_red;
    }
    .toggle.is-active span::before {
        top: 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    .toggle.is-active span::after {
        bottom: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .toggle span::after {
        -webkit-transition-property: bottom,-webkit-transform;
        transition-property: bottom,-webkit-transform;
        transition-property: bottom,transform;
        -webkit-transition-property: bottom,-webkit-transform,-webkit-transform;
        transition-property: bottom,transform,-webkit-transform;
        bottom: -6px;
    }
    .menu-wrapper .block {
        height: 0;
        width: 100%;
        overflow: hidden;
        display: none;
    }
    .menu-wrapper.active .block {
        height: auto;
        display: block;
    }
    #header.fixed {
      nav {
        .logobar {
          img {
            margin-left: 15px;
          }
        }
      }
    }

    #header nav {
        padding-right: 0 !important;
        
        .main-menu {
            .block {
                float: left;
            }
            
            #block-system-main-menu {
                left: 0;
                margin-left: 0;
                position: relative;
            }
            #block-menu-block-1 {
                overflow: visible;
            }
            
            width: 100%;
            .menu {
                height: auto;
                box-shadow: inset 0px -10px 13px -10px rgba(0,0,0,0.75);
                background-color: $colors_grey_light;
                float: left;
                width: 100%;
                padding: 25px 0 125px;

                li {
                    width: 100%;
                    // height: 45px;
                    line-height: 45px;
                    margin-left: 20px;
                    height: auto;
                    
                    a {
                        color: $color_text_grey;
                    }
                    
                    .menu {
                        display: none;
                        &.active {
                            display: block;
                            height: auto;
                            padding-bottom: 5px;
                            z-index: 100;
                            position: relative;
                            margin-left: 0;
                            top: 0px;
                            width: 100%;
                            left: -10px;
                            padding-left: 10px;
                        }
                        
                        li {
                            display: block;
                            a.menu_icon {
                                margin-top: 0;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .block-search {
                position: absolute;
                top: 17px;
                right: 56px;
                margin-top: 0;
                overflow: visible;
            }
            
            .menu-name-menu-second-main-menu {
                margin: 0 0 0 0;
                opacity: 0;
                
                .menu {
                    box-shadow: none;
                    background-color: transparent;
                    margin-top: -100px;
                    position: absolute;
                    left: 10px;
                    
                    li {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }
            .active {
                .menu-name-menu-second-main-menu {
                    opacity: 1;
                }
            }
        }
        
        .main-menu .block-locale,
        .main-menu .block-extra-locale {
            height: 36px;
            
            &.active {
                display: block;
                margin-top: -115px;
                left: 20px;
                top: -7px;
                width: 200px;
                background-color: #ffffff;
            }
            
            &.language-open {
                height: 252px;
            }
        }
        
    }
    .view-artikel-overzicht {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .view-artikel-overzicht.overzicht-1 .views-row-1, 
    .view-artikel-overzicht.overzicht-4 .views-row-1 {
        height: 300px;
        
        .article-title { 
            height: 300px;
            &:after {
                top: 115px;
            }
        }
        .views-field-field-startafbeelding {
            height: 300px;
            
            .home-bg-image {
                height: 300px;
            }
        }
    }
    .view-artikel-overzicht.overzicht-2 .views-row, 
    .view-artikel-overzicht.overzicht-3 .views-row, 
    .view-artikel-overzicht.overzicht-5 .views-row {
        height: 250px;
        .article-title {
            bottom: 20px;
            left: 35px;
            
            .views-field-field-opgemaakte-titel-regel-1,
            .views-field-field-opgemaakte-titel-regel-2 {
                font-size: 17px;
            }
        }
        .views-field-field-startafbeelding {
            height: 250px;
            
            .home-bg-image {
                height: 250px;
            }
        }
    }
    .view-artikel-overzicht.overzicht-5 {
        overflow-x: auto;
        
        .view-content {
            width: 200%;
            height: 250px;
        }
    }
    #block-global-filter-global-filter-2 .content li {
        margin-bottom: 10px;
    }
    .node-type-home .view-usp-s .views-row .views-field-field-usp-subtitel {
        padding: 0 25px;
    }
    .node-type-home .view-home-leeftijdenwidget.slide .view-header {
        margin-bottom: 10px;
        font-size: 34px;
    }
    .node-type-home .view-home-leeftijdenwidget.slide .view-content .views-row {
        width: 100%;
        padding-bottom: 0px;
        padding-top: 10px;
    }
    .view-artikel-overzicht.overzicht-1 .views-row-1 .article-title .views-field-field-opgemaakte-titel-regel-1,
    .view-artikel-overzicht.overzicht-4 .views-row-1 .article-title .views-field-field-opgemaakte-titel-regel-1 {
        margin-top: 40px;
        font-size: 24px;
    }
    .view-artikel-overzicht.overzicht-1 .views-row-1 .article-title .views-field-field-opgemaakte-titel-regel-2, 
    .view-artikel-overzicht.overzicht-4 .views-row-1 .article-title .views-field-field-opgemaakte-titel-regel-2 {
        /*font-size: 54px;*/
        font-size: 28px;
    }
    .node-type-leeftijdsfase {
        .view-header-leeftijdfasepagina {
            .views-field-field-headertekst {
                padding: 0 15px;
                box-sizing: border-box;
                top: calc(30vh - 125px);

                .field-content {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .backlink {
                padding: 0 15px;
                box-sizing: border-box;
                top: calc(20vh - 125px);

                div {
                    padding-right: 0;
                }
            }
        } 
        .group-subbox .field {
            width: 100%;
        }
        
        .view-leeftijdsfase-tips .views-field-field-tips-1, 
        .view-leeftijdsfase-tips .views-field-field-tips-titel,
        .view-leeftijdsfase-tips .views-field-field-tips {
            width: 100%;
            padding: 0 15px;
            margin-top: 0;
        }
        .view-leeftijdsfase-tips .views-field-field-tips-titel h3 {
            padding-right: 0;
            font-size: 32px;
        }
        .view-leeftijdsfase-verzorging .views-field-field-verzorging-tekst h2 {
            font-size: 38px;
            line-height: 46px;
        }
        .view-leeftijdsfase-verzorging .views-field-field-usp-s li {
            width: 100%;
            padding: 0 25px !important;
        }
        .view-leeftijdsfase-eigenaren .view-header h2 {
            font-size: 38px;
            line-height: 46px;
        }
        .view-leeftijdsfase-eigenaren .view-content .views-row {
            width: 100%;
            padding: 0 15px !important;
            margin-bottom: 20px;
            overflow: hidden;
            
            .views-field-field-usp-titel {
                margin-top: 15px;
                line-height: 34px;
            }
            .views-field-field-usp-titel h3 {
                padding: 0 15px;
                
                &:before {
                    width: 38px;
                    height: 50px;
                }
            }   
            .views-field-field-usp-subtitel {
                display: none;
            }
        }
    }
    .node-type-ontdek-prins {
        #block-system-main .field.field-name-field-usp-s > .field-items > .field-item {
            width: 50%;
        }
        #block-system-main .field.field-name-body h2 {
            margin-top: 0;
        }
    } 
    .node-type-vacature-overzicht,
    .node-type-vacature {
        .view-header-vacature {
            height: 200px;
            
            .views-field-field-startafbeelding,
            .home-bg-image {
                height: 200px;
            }
        }
        #block-system-main .field.field-name-body h2 {
            margin-top: 0;
        }
        .view-alle-vacatures .views-row {
            padding: 15px 15px;
            box-sizing: border-box;
            
            .views-field-title h3 {
                font-size: 18px;
            }
            .views-field-title p {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
    }
    .node-type-vacature {
        #block-views-alle-vacatures-block {
            h2 {
                text-align: center;
            }
            
            padding: 40px 15px;
            box-sizing: border-box;
            
            .views-row {
                height: auto;
                text-align: center;
                overflow: hidden;
                
                .views-field-view-node {
                    display: none;
                }
            }
        }
        .footer #block-views-meer-artikelen-over-block-1 {
            margin-top: 40px;
        }
    }
    .footer #block-views-meer-artikelen-over-block-1 {
        margin-top: 0;
    }
    .page-taxonomy-term  {
        .view-category-header {
            height: 300px;
            
            .views-field-field-startafbeelding, 
            .home-bg-image {
                height: 300px;
            }
            .views-field-description-1 {
                top: 10vh;
            }
            .views-field-description-1 .field-content {
                padding: 0 15px;
                box-sizing: border-box;
                
                h4 {
                    font-size: 24px;
                    line-height: 32px;
                }
                h1 {
                    font-size: 24px;
                    line-height: 32px;
                }
                p {
                    font-size: 16px;
                }
            }
        }
        .view-productlijn-overzicht .views-row {
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            margin-bottom: 25px;
            
            &.views-row-even {
                padding-left: 15px;
            }
            &.views-row-odd {
                padding-right: 15px;
            }
            .views-field-nothing, 
            h3 {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }
            .views-field-field-product-afbeelding {
                width: 100%;
                text-align: center;
                margin-top: 0;
                
                img {
                    max-height: 200px;
                    width: auto;
                }
            }
        }
        #block-views-productlijn-sfeer-block-1 {
            .view-productlijn-sfeer {
                height: 400px;
            }
            .views-field-field-quote,
            .views-field-field-quote-afbeelding {
                width: 100%;
                height: 200px;
                .footer-bg-image {
                    height: 200px;
                }
            }
            .views-field-field-quote .field-content {
                font-size: 34px;
                line-height: 42px;
            }
        }
        .view-productlijn-sfeer {
            height: 150px;
            
            .footer-bg-image {
                height: 150px;
            }
        }
        .view-productlijn-overzicht {
            padding: 40px 0 20px;
        }
        .vocabulary-product-category {
            .field-name-field-productlijnomschrijving,
            .field-name-field-product-afbeelding,
            .field-name-field-productlijnomschrijving {
                width: 100%;
            }
            .field-name-field-productlijnomschrijving {
                text-align: center;
                h2 {
                    font-size: 34px;
                    line-height: 42px;
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .group-samenstelling {
                background-size: cover;
                
                .field-name-field-samenstellingomschrijving, 
                .field-name-field-samenstelling-afbeelding {
                    width: 100%;
                }
                .field-name-field-samenstellingomschrijving {
                    padding: 20px;
                    font-size: 16px;
                    line-height: 22px;
                    
                    .field-label {
                        font-size: 34px;
                        line-height: 42px;
                    }
                }
                .field-name-field-samenstelling-afbeelding:before {
                    display: none;
                }
            }
            .field-name-field-voordelen p {
                font-size: 16px;
                line-height: 24px;
            }
            .field-name-field-opsomming-voordelen .field-item {
                width: 100%;
                min-height: 65px;
                height: auto;
                line-height: 24px;
                padding: 10px 0 10px 35px;
                background-position-y: 12px;
                background-size: 22px 22px;
            }
        }
        #block-views-productlijn-wanneer-block .views-row .views-field-field-usp-s li {
            width: 100%;
        }
        .view-productlijn-producten .view-header {
            font-size: 34px;
            line-height: 42px;
            padding: 0 15px;
        }
        .view-productlijn-producten .view-content {
            margin-left: 0;
            margin-right: 0;
        }
        .view-productlijn-producten .view-content .views-row {
            width: 100%;
        }
    }
    .node-type-product {
        .header-interactive {
            max-height: calc(100vh - 145px);
        }
        .header-interactive .view-header-producten {
            max-height: calc(100vh - 145px);
            
            .product-titel {
                top: 50px;
                text-align: center;
                
                .views-field-title {
                    line-height: 52px;
                    text-align: center;
                }
            }
            
            .views-field-field-product-afbeelding {
                width: 100%;
                position: absolute;
                top: 250px;
                right: 0;
                margin-right: 0;
                text-align: center;
                
                img {
                    width: 60%;
                    height: auto;
                }
            }
        }
        .main-spanner .group-subbox .field {
            width: 100%;
        }
        .main-spanner .field-name-field-usp-s > .field-items > .field-item,
        .main-spanner .field-name-field-gerelateerde-artikelen > .field-items > .field-item {
            width: 100%;
        }
        .main-spanner .field-name-field-gerelateerde-artikelen > .field-items > .field-item {
            margin-bottom: 15px;
        }
        // .main-spanner #group-foodinfo-node-product-default ul.resp-tabs-list {
        //     display: none;
        // }

        .main-spanner #group-foodinfo-node-product-default .resp-tab-content:nth-child(2) .field {
            width: 100%;
            margin-bottom: 15px;
        }
        .main-spanner #group-foodinfo-node-product-default h2.resp-accordion {
            border: 0 none;
            border-top: 1px solid $colors_white;
            background-color: $colors_grey_bg !important;
            color: $colors_white;
            
            .resp-arrow {
                margin-top: 6px;
            }
        }
    } 
    
    #block-global-filter-global-filter-1,
    #block-global-filter-global-filter-2 {
        text-align: center;
        
        .content {
            float: none;
            display: inline-block;
        }
    }
    #header .view-header-artikel {
        .views-field-title {
            padding: 0 15px;
            top: calc(35vh - 125px);
            box-sizing: border-box;
            
            h1 {
                font-size: 42px;
                line-height: 50px;
            }
            h2 {
                font-size: 20px;
            }
        }
    }
    #main .view-artikel-auteur-header .views-field-nothing .auteur_team_contact {
        height: auto;
        line-height: 40px;
        padding: 20px 0;
        margin-bottom: 0px;
    }
    
    .copyright {
        width: 100%;
        text-align: center;

        #logo-footer {
            float: none;
            padding-right: 0;
        }
        .region-copyright {
            width: 100%;

            .menu li, 
            .block .menu li {
                width: 100%;
                text-align: center;
                margin-right: 0;
                height: 40px;
                line-height: 40px;
            }
        }
        .block-locale {
            float: none;
            display: inline-block;
            margin-right: 0;
        }
        .social-icons {
            float: left;
            display: block;
            width: 100%;
            margin-bottom: 30px;

            .content {
                text-align: center;
                width: 100%;

                ul {
                    display: inline-block;
                }
            }
        }
    }
    
    .view-populair-bij-prins.overzicht-5 .views-row {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .node-type-home .view-home-leeftijdenwidget.slide .view-content {
        padding: 0 0;
        
        .views-row {
            width: 33%;
            
            p {
                font-size: 14px;
            }
        }
    }
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-headertekst p {
        font-size: 16px;
        line-height: 26px;
    }
    #header .view-home-header, 
    #header .view-header-artikel,
    #header .view-home-header .views-field-field-startafbeelding, 
    #header .view-home-header .home-bg-image, 
    #header .view-header-artikel .views-field-field-startafbeelding, 
    #header .view-header-artikel .home-bg-image{
        min-height: 567px !important;
        max-height: 567px !important;
    }
    .node-type-leeftijdsfase .view-leeftijdsfase-verzorging .views-field-field-verzorging-tekst p {
        font-size: 16px;
        line-height: 24px;
    }
    .node-type-leeftijdsfase .view-leeftijdsfase-verzorging .views-field-field-verzorging-items li {
        width: 100%;
        margin: 0 0 0 0;
        padding: 0 40px;
        text-align: center;
        &.first {
            padding-left: 40px;
        }
        &.last {
            padding-right: 40px;
        }
    }
    .node-type-leeftijdsfase .view-leeftijdsfase-verzorging .views-field-field-usp-s li img, 
    .node-type-leeftijdsfase .view-leeftijdsfase-verzorging .views-field-field-verzorging-items li img {
        max-width: 140px;
    }
    .node-type-prins-artikel .view-header-artikel .backlink, 
    .node-type-tips-info .view-header-artikel .backlink {
        top: 40px;
    }
    .node-type-prins-artikel .view-overzicht-levensfasen-onder-artikel .view-content .views-row, 
    .node-type-tips-info .view-overzicht-levensfasen-onder-artikel .view-content .views-row {
        width: 50%;
    }
    .voedingswijzer .step-products .node-product {
        width: 50%;
    }
}


@media all and (max-width: $breakpoints_xs){
    $navbar_height: 66px;
    
    #header.fixed {
        margin-top: $navbar_height;
        
        nav {
            height: $navbar_height;
            
            .logobar {
                height: $navbar_height;
                
                img {
                    width: 20%;
                    height: auto;
                    margin-left: 15px;
                    margin-top: 7px;
                }
            }
        }
    }
    .node-type-home {
        .view-usp-s .views-row {
            width: 50%;
            &.views-row-3 {
                clear: left;
            }
        }
        .main-spanner {
            padding-top: 40px;
        }
        #header .view-home-header {
            .views-field-field-video-titel {
                font-size: 70px;
                line-height: 70px;
                top: 100px;
            }
            .view-header-animal-choice {
                top: 330px;
            }
        }
        .header-interactive {
            height: auto;
        }
        .content .field-name-title-field {
            line-height: 45px;
        }
        .view-usp-s .views-row .views-field-field-usp-subtitel {
            padding: 0 15px;
        }
    }
    .view-artikel-overzicht.overzicht-2,
    .view-artikel-overzicht.overzicht-3,
    .view-artikel-overzicht.overzicht-5 {
        margin-left: 0;
        margin-right: 0;
        .views-row {
            width: 100%;
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
    .page-node-4 .second-main .view-artikel-overzicht.overzicht-2 .views-row:nth-child(8n+1) {
        width: 100%;
        max-width: 100%;
    }
    .view-artikel-overzicht.overzicht-1,
    .view-artikel-overzicht.overzicht-4 {
        margin-left: 0;
        margin-right: 0;

        .views-row-1 {
            display: flex;
            flex-flow: row wrap;
            height: auto;

            .article-title {
                width: 100%;
                order: 2;
                flex: 1 100%;
                height: 360px;

                .views-field-field-opgemaakte-titel-regel-1 {
                    margin-top: 0;
                }
                .views-field-field-opgemaakte-titel-regel-2 {
                    font-size: 42px;
                }

                &:after {
                    display: none;
                }
            }
            .views-field-view-node {
                display: none;
            }
            .views-field-field-startafbeelding {
                height: 200px;
                width: 100%;
                order: 1;
                flex: 1 100%;

                .home-bg-image {
                    height: 200px;
                }
            }
        } 
    } 
    
    .newsletter-signup  {
        .content form .form-group {
            text-align: center;
            
            input[type="email"] {
                width: 50%;
                
            }
        }
    }
    
    .node-type-prins-artikel .view-overzicht-levensfasen-onder-artikel .view-content .views-row, 
    .node-type-tips-info .view-overzicht-levensfasen-onder-artikel .view-content .views-row {
        width: 100%;
    }
    .voedingswijzer .step-products .node-product {
        width: 100%;
    }
    .voedingswijzer #voedingswijzer-sidebar {
        width: 100%;
        border-radius: 0 !important;
    }
    .voedingswijzer .step-products {
        width: 100%;
    }
    .page-taxonomy-term .view-productlijn-overzicht .views-row {
        width: 98%;
    }
    
    .node-type-product {
        .header-interactive .view-header-producten {
            .product-titel .views-field {
                padding-right: 0;
                
                h1 {
                    text-align: center;
                }
                h3 {
                    font-size: 20px;
                }
                &.views-field-nothing a {
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }
            .views-field-field-product-afbeelding {
                top: 460px;
            }
        }
        .main-spanner .field-name-body {
            margin-top: 200px;
            padding: 0 20px;
            
            h2 {
                @include mobile_header_1();
            }
            p {
                @include mobile_text();
            }
        }
        .main-spanner .group-subbox {
            background-size: cover;
            padding-bottom: 20px;
            
            .field.field-name-field-subheader {
                @include mobile_header_1();
            }
            p {
                @include mobile_text();
            }
        }
        .main-spanner #group-foodinfo-node-product-default .resp-tab-content {
            padding-top: 10px;
            .field table {
                font-size: 13px;
                
                td {
                    padding: 0 10px;
                }
            }
            .field p {
                font-size: 13px;
                line-height: 22px;
            }
        }
        .google-store-locator-panel .storelocator-filter input {
            margin-left: 10%;
            width: 80%;
        }
        .second-main .block-center h2 {
            @include mobile_header_1();
            padding: 25px 0 15px;
        }
    }
    .voedingswijzer .voedingswijzer-step {
        .answers li {
            margin: 0 0px;
            max-width: 47%;
            padding: 0 5px;
            box-sizing: border-box;
        }
    } 
    .node-type-ontdek-prins #block-system-main .field.field-name-body .gallery {
        margin-left: -20px;
        margin-right: -20px;
    }
    .node-type-ontdek-prins #block-system-main .field.field-name-body .gallery td {
        width: 33%;
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        
        img {
            width: 100%;
            height: auto;
        }
    }
    .node-type-tekstpagina .view-header-vacature, 
    .node-type-tekstpagina .view-header-vacature .views-field-field-startafbeelding, 
    .node-type-tekstpagina .view-header-vacature .home-bg-image {
        height: 300px;
        background-position: center center;
    }
    .node-type-tekstpagina #block-system-main {
        
        .field.field-name-field-headertekst h1 {
            @include mobile_header_1();
            margin-bottom: 20px;
        }
        .field.field-name-field-headertekst p {
            @include mobile_text();
        }
    }
    .node-type-tekstpagina #block-system-main .field.field-name-body h2, 
    .node-type-vacature #block-system-main .field.field-name-body h3, 
    .node-type-ontdek-prins #block-system-main .field.field-name-body h2 {
        @include mobile_header_1();
        margin: 20px 0 10px 0;
    }
    .node-type-ontdek-prins {
        .view-header-vacature {
            .views-field-field-startafbeelding, 
            .home-bg-image, 
            .views-field-field-vimeo-video {
                height: 210px;
            }
        }
        
        #block-system-main .field.field-name-field-headertekst h1 {
            @include mobile_header_1();
            margin: 0px 0 10px 0;
        }
        #block-system-main .field.field-name-field-headertekst p,
        #block-system-main .field.field-name-body p {
            @include mobile_text();
        }
    }
    .node-type-webform #block-system-main {
        .field.field-name-field-headertekst h1 {
            @include mobile_header_1();
        }
        .field.field-name-field-headertekst p {
            @include mobile_text();
        }
        .callout01 {
            margin-right: 0;
        }
        .field.field-name-body p {
            @include mobile_text();
        }
        .node .content ul.menu li {
            padding: 7px 10px !important;
            @include mobile_text();
            border-radius: 8px;
            margin-bottom: 5px !important;
        }
        .node .content ul.menu {
            height: auto;
            margin-bottom: 5px !important;
        }
        .webform-client-form {
            padding: 20px 20px;
            
            & > div .form-item input {
                width: 100%;
            }
            & > div .form-item input[type="image"] {
                width: auto;
            }
            & > div .form-item label {
                width: 100%;
            }
            & > div .form-actions input {
                margin-left: 0;
            }
            & > div .form-item .form-textarea-wrapper textarea,
            & > div .form-item .form-textarea-wrapper {
                width: 100%;
            }
        }
    }
    #header nav .main-menu .menu li .menu {
        top: 66px;
    }
    .google-store-locator-panel {
        width: 340px;
    }
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-vimeo-video,
    .page-taxonomy-term .view-category-header .views-field-field-vimeo-video {
        display: none;
    }
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina, 
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina 
    .views-field-field-startafbeelding, 
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .home-bg-image,
    .page-taxonomy-term .view-category-header .views-field-field-startafbeelding {
        opacity: 1 !important;
        display: block !important;
    }
    .page-taxonomy-term .view-category-header .views-field-field-startafbeelding.video-under-it:after {
        display: none;
    }
}
@media all and (max-width: 768px) and (min-height: 1000px) {
    .node-type-leeftijdsfase .view-leeftijdsfase-eigenaren .view-content .views-row .views-field-field-usp-titel {
        margin-top: 50px;
    }
    .node-type-leeftijdsfase .view-leeftijdsfase-eigenaren .view-content .views-row .views-field-field-youtube-video-1 {
        margin-bottom: 50px;
    }
}
@media all and (max-width: $breakpoints_mobile) {
    #header.fixed nav .logobar img {
        width: 26%;
    }
    #header .view-home-header .views-field-field-video-titel .field-content {
        font-size: 48px;
    }
    .google-store-locator-panel {
        width: 280px;
        top: 20px;
        left: 20px;
    }
    .uitgezoomd-melding span {
        font-size: 14px;
        line-height: 22px;
        padding: 5px 15px;
    }
    .uitgezoomd-melding span:before {
        padding: 3px 12px;
    }
    .node-type-ontdek-prins #block-system-main .field.field-name-body table.gallery p {
        font-size: 14px;
        line-height: 22px;
    } 
    #header .view-home-header, 
    #header .view-header-artikel,
    #header .view-home-header .views-field-field-startafbeelding, 
    #header .view-home-header .home-bg-image, 
    #header .view-header-artikel .views-field-field-startafbeelding, 
    #header .view-header-artikel .home-bg-image{
        min-height: 300px !important;
        max-height: 300px !important;
    }
    .front #header .view-home-header, 
    .front #header .view-header-artikel,
    .front #header .view-home-header .views-field-field-startafbeelding, 
    .front #header .view-home-header .home-bg-image, 
    .front #header .view-header-artikel .views-field-field-startafbeelding, 
    .front #header .view-header-artikel .home-bg-image{
        min-height: 567px !important;
        max-height: 567px !important;
    }
}

@media all and (max-width: 1160px) {
    #header nav .main-menu .menu li,
    #header nav .main-menu .menu-name-menu-second-main-menu {
        margin-top: 0;
    }
    #header nav .main-menu #block-system-main-menu {
      left: 45%;
    }
}
@media all and (max-width: 768px) {
    #header nav .main-menu #block-system-main-menu {
        left: 0%;
    }
}

@media all and (max-width: 768px) {
    .node-type-product .main-spanner #group-foodinfo-node-product-default ul.resp-tabs-list {
        display: none;
    }
}

$default_width: 100%;
$default_max_width: 1366px;
$default_font_size: 16px;
$default_font_lineheight: 28px;

$colors_base: #6a6a6a;
$colors_base_hover: #222222;
$color_grey: #868686;
$color_text_grey: #343434;
$colors_grey_light: #eeeeee;
$colors_green: #98c010;
$colors_green_light: #c9e763;
$color_green_input: #b7d257;
$color_green_lighter: #eff5d9;
$colors_red: #e5322d;
$colors_white: #ffffff;
$colors_grey_bg: #25303d;
$colors_grey_bg_light: #454f5a;
$color_blue_bg_dark: #2a3745;


$breakpoints_xxl: 1406px;
$breakpoints_xl: 1320px;
$breakpoints_l: 1160px;
$breakpoints_m: 1064px;
$breakpoints_tablet: 1024px;
$breakpoints_s: 808px;
$breakpoints_xs: 667px;
$breakpoints_mobile: 420px;

$header_height: 145px;
$menu_margin: 60px;
$search_height: 35px;
$header_banner_height: 650px;

@mixin drupal_tabs () {
    // .tabs-wrapper {
    //     position: fixed;
    //     left: -130px;
    //     bottom: 161px;
    //     background-color: #80a0c4;
    //     border: 1px solid #80a0c4;
    //     z-index: 100; 
    //     transition: left 0.3s;
    // }
    // .tabs-wrapper:hover {
    //     left: 0;
    // }
    // .tabs-wrapper .tabs {
    //     padding: 0 0 0 0;
    //     border-bottom: 0 none; 
    // }
    // .tabs-wrapper .tabs li {
    //     display: block;
    //     border: 0 none;
    //     width: 100%;
    //     margin-bottom: 5px; 
    // }
    // .tabs-wrapper .tabs li a {
    //     display: block;
    //     margin: 0 0 0 0;
    //     padding: 5px 30px 5px 15px;
    //     border: 0 none;
    //     color: #ffffff;
    //     border: 0 none;
    //     border-radius: 3px;
    //     background-color: #2b2d2f;
    //     position: relative; 
    // }
    // .tabs-wrapper .tabs li a:hover {
    //     border: 0 none;
    //     color: #ffffff;
    //     background-color: #000000; 
    // }
    // .tabs-wrapper .tabs li.active a {
    //     border: 0 none;
    //     color: #ffffff;
    //     background-color: #000000; 
    // }
}

@mixin header_down_btn(){
    width: 80px;
    height: 80px;
    border-radius: 40px;
    @include in_icon('../images/icon-arrow-white.png', $colors_red);
    background-size: 35px 13px;
    position: absolute;
    left: 50%;
    bottom: -40px;
    margin-left: -40px;
    z-index: 99;
    display: block;
}

@mixin video_header($header_banner_height, $colors_grey_bg){
    width: 100%;
    position: relative;
    background-color: $colors_grey_bg;

    .views-field-field-startafbeelding, 
    .home-bg-image, 
    .views-field-field-vimeo-video,
    .views-field-field-vimeo-video-kat,
    .views-field-field-vimeo-video-hond {
        width: 100%;
        height: $header_banner_height;
        position: relative;
        opacity: 1;
    }
    .views-field-field-startafbeelding.video-under-it {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        opacity: 0;
    }
    .views-field-field-startafbeelding.video-under-it:after {
        content: "";
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        margin-left: -24px;
        margin-top: -24px;
        width: 48px;
        height: 48px;
        background: transparent url('../images/icon-play.png') center center no-repeat;
    }
    .views-field-field-vimeo-video,
    .views-field-field-vimeo-video-kat,
    .views-field-field-vimeo-video-hond {
        overflow: hidden;
    }
    .views-field-field-vimeo-video-kat.active,
    .views-field-field-vimeo-video-hond.active {
        position: absolute;
        top: 0;
        
        video {
            opacity: 1;
        }
        
        ul {
            li {
                position: absolute;
                top: 0;
                width: 100%;
                height: 650px;
                margin: 0 0 0 0;
                
                &.first {
                    z-index: 98;
                }
                &.last {
                    z-index: 97;
                }
            }
        }
    }
    video {
        background-color: $colors_grey_bg;
        float: left;
        width: 100%;
        height: auto;
        opacity: 0.5;
    }
    
}

@mixin responsive_header($header_height, $font_size, $menu_margin, $searchbar: 21px) {
    #header {
        nav {
            height: $header_height;
            
            .logobar {
                height: $header_height;
                float: left;

                img {
                    height: ($header_height - 30);
                }
            }
            
            .main-menu {
                .menu-name-menu-second-main-menu {
                    margin-left: ($header_height - 50px);
                }
                
                .menu {
                    margin-top: $menu_margin;
                    height: ($header_height - $menu_margin);
                    
                    li {
                        height: ($header_height - $menu_margin);
                        line-height: ($header_height - $menu_margin);
                        
                        a {
                            font-size: $font_size;
                        }
                        .menu {
                            top: ($header_height);
                        }
                    }
                }
                
                .block-search {
                    margin-top: ($searchbar + $menu_margin);
                }
                .block-extra-locale {
                    top: (-25px + $menu_margin);
                }
            }
        }
        .view-home-header, .view-header-artikel {
            max-height: -webkit-calc(100vh - #{$header_height});
            max-height: -moz-calc(100vh - #{$header_height});
            max-height: calc(100vh - #{$header_height});
                
            .views-field-field-startafbeelding, .home-bg-image {
                max-height: -webkit-calc(100vh - #{$header_height});
                max-height: -moz-calc(100vh - #{$header_height});
                max-height: calc(100vh - #{$header_height});
                background-position: center center;
            }
        }
        
        &.fixed {
            margin-top: $header_height;

            nav { 
                height: $header_height;
            }
        }
    }
}

@mixin in_icon($url, $color) {
    background: $color url($url) center center no-repeat;
    background-size: cover;
}

@mixin placeholder($color){
    &::-webkit-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder { 
        color: $color;
    }
}
@mixin icon($image) {
    &.icon-#{$image} { 
        background: transparent url('../images/icon-#{$image}.png') center center no-repeat;
        background-size: cover;
    }
}


@mixin leeftijdsfase_header($header_size) {
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .backlink > div, 
    .node-type-leeftijdsfase .view-header-leeftijdfasepagina .views-field-field-headertekst .field-content {
        max-width: calc( #{$header_size} - 30px );
    }
}

@mixin default_submenu() {
    width: 100%;
        
    ul {
        list-style: none;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;
            padding: 0 20px;

            a {
                color: $colors_base;
                text-transform: uppercase;
                height: 50px;
                line-height: 50px;
                text-decoration: none;
                transition: all 0.3s;

                &:hover,
                &.active {
                    color: $color_text_grey;
                }
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

@mixin button_red (){
    background-color: $colors_red;
    color: $colors_white;
    text-transform: uppercase;
    height: $search_height;
    line-height: $search_height;
    border-radius: ($search_height / 2);
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    font-size: 14px;
    padding: 0 15px;

    &:hover {
        color: $colors_white;
        opacity: 0.7;
    }
}
@mixin button_green (){
    background-color: $colors_green;
    color: $colors_white;
    text-transform: none;
    height: $search_height;
    line-height: $search_height;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 17px;
    padding: 0 10px 0 13px;
    text-decoration: none;

    &:hover {
        color: $colors_white;
        opacity: 0.7;
    }
    &:after {
        content: " \203A ";
        transition: all 0.3s;
    }
    &:hover:after {
        padding-left: 5px;
    }
}
@mixin placeholder($color, $opacity:1) {
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    $color;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color:    $color;
       opacity:  $opacity;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color:    $color;
       opacity:  $opacity;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:    $color;
    }
}


@mixin backlink(){
    a {
        color: $colors_white;
        text-decoration: none;
        font-size: 20px;
        font-weight: normal;
        transition: opacity 0.3s;

        &:before {
            content: " \2039 ";
            font-size: 30px;
            transition: all 0.3s;
            margin-right: 10px;
        }
        &:hover {
            opacity: 0.75;
        }
        &:hover:before {
            padding-right: 5px;
        }
    }
}

@mixin mobile_header_1 () {
    font-size: 24px;
    line-height: 34px;
}
@mixin mobile_text () {
    font-size: 16px;
    line-height: 26px;
}

@mixin body_style() {

  padding: 40px 0 0;
  margin-bottom: 20px;

  @media all and (max-width: $breakpoints_s) {
    padding: 50px 15px 0 15px;
  }

  p,
  h2,
  h3,
  h4,
  ol,
  ul,
  blockquote {
    max-width: 980px;
    margin: 0 auto 20px;
    position: relative;
  }

  ol {
    ol {
      margin-left: 40px;
    }
  }

  @media all and (max-width: $breakpoints_s) {

    ol,
    ul {
      padding-left: 15px;
    }
  }

  p.image-wide {
    max-width: 1280px;
    margin-top: 40px;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  p.image-wide-footer {
    text-align: center;
    font-style: italic;
    border-bottom: 1px solid #eeeeee;
    max-width: 1280px;
    margin-top: -10px;
    padding-bottom: 18px;
    font-size: 1.2em;
    margin-bottom: 40px;
  }

  blockquote p {
    border-left: 8px solid $colors_red;
    padding: 8px 0 8px 30px;
    font-family: 'Sniglet', sans-serif;
    font-size: 28px;
    line-height: 36px;
  }

  @media all and (max-width: $breakpoints_xs) {
    blockquote p {
      font-size: 30px;
      line-height: 38px;
    }
  }

  h2 {
    margin-top: 40px;
    font-size: 2.0em;
    line-height: 1.2em;
  }

  h3 {
    margin-top: 40px;
    font-size: 1.8em;
    line-height: 1.2em;
  }

  a {
    color: $colors_red;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
    &.cta {
        @include button_green();
    }
  }

  ul {
    li {
      list-style: none;
      position: relative;
      margin-left: 20px;

      &:before {
        content: "";
        background: transparent url('../images/list-icon.png') center center no-repeat;
        background-size: cover;
        position: absolute;
        width: 22px;
        height: 22px;
        left: -25px;
        top: 2px;
      }
    }
  }

  @media all and (max-width: $breakpoints_xs) {
    ul {
      margin-left: 25px;
    }
  }

  .gallery {
    max-width: 990px;
    margin: 0 auto 20px;
    position: relative;
    border-collapse: collapse;

    td {
      padding: 5px;

      img {
        width: 100%;
        height: auto;
        float: left;
      }
    }
  }
}